import styled from 'styled-components';
import { theme } from '@styles';

const { colors } = theme;

export const StyledMap = styled.iframe`
  border: 0;
  max-width: 100%;
  width: 100%;
  height: 500px;
`;

export const Wrapper = styled.div`
  padding-top: 75px;
`;

export const IconBox = styled.div`
  text-align: center;
  display: inline-block;
  margin-bottom: 40px;
  width: 100%;

  svg {
    color: ${colors.accent};
    width: 3em;
  }
`;

export const FormWrapper = styled.div`
  background-color: ${colors.main};
  padding: 30px;
  margin-top: 1rem;
  margin-bottom: 6rem;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  justify-content: center;
`;

export const FormGroup = styled.div`
  margin-bottom: 0;
  margin-top: 10px;
  position: relative;
`;

export const StyledLabel = styled.label`
  color: ${colors.text};
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.05em;
  letter-spacing: 0.05em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
`;

export const Required = styled.span`
  color: #dc5753;
`;

export const StyledInput = styled.input`
  background-color: ${colors.dark};
  color: ${colors.light};
  border: none;
  font-size: 16px;
  height: 60px;
  padding-bottom: 9px;
  padding-left: 30px;
  padding-right: 20px;
  padding-top: 9px;
  width: 100%;
  border-radius: 0.25rem;
`;

export const StyledTextArea = styled.textarea`
  min-height: 260px;
  height: auto;
  background-color: ${colors.dark};
  color: ${colors.light};
  border: none;
  font-size: 16px;
  padding: 20px 20px 9px 30px;
  width: 100%;
  border-radius: 0.25rem;
`;

export const ErrorMessage = styled(Required)`
  margin: 1rem 0;
`;

export const SuccessMessage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  h3 {
    color: ${colors.text};
    font-size: 18px;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 0.9em;
    font-weight: 400;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    word-break: break-word;
  }
`;
