import React from 'react';
import SEO from '@components/seo';
import BreadCrumb from '@components/breadcrumb';
import Contact from '@views/contact';

export default () => {
  return (
    <>
      <SEO
        title="Contact us"
        uri="contact"
        desc="We're here to help. Dynamo Fencing Center Inc. 11 Homer St Newton, MA 02459 +1 617-356-7887."
      />
      <BreadCrumb name="Contact" />
      <Contact />
    </>
  );
};
