import styled from 'styled-components';
import { theme, media } from '@styles';

const { colors } = theme;

export const BrithdaysSection = styled.section`
  width: 100%;
  height: auto;
  padding: 3rem 0;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

const BaseCol = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const Col = styled(BaseCol)`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;

  ${media.desktop`
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  `};
`;

export const PricingTable = styled.div`
  box-shadow: 0 0 7px 0 rgba(77, 109, 230, 0.35);
  text-align: center;
  padding: 38px 0 40px;
  transition: 0.3s ease-in;
  margin-bottom: 30px;

  ul {
    margin: 0;
    padding: 0;

    li {
      margin-top: 0;
      list-style: none;
      display: block;
      color: ${colors.light};
      margin: 27px 0;
    }
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
`;

export const FooterWrapper = styled.div`
  margin-top: 40px;

  a {
    justify-content: center;
  }
`;

export const Badge = styled.span`
  position: absolute;
  left: 50%;
  top: -60px;
  width: 80%;
  font-weight: bold;
  background-color: ${colors.accent};
  color: #000;
  padding: 10px 0;
  transform: translateX(-50%);

  :before {
    content: '';
    position: absolute;
    right: -40px;
    top: 0;
    border-left: 20px solid ${colors.accent};
    border-right: 20px solid transparent;
    border-top: 22px solid transparent;
  }

  :after {
    content: '';
    position: absolute;
    left: -40px;
    top: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid ${colors.accent};
    border-top: 22px solid transparent;
  }
`;

export const IconWrapper = styled.div`
  line-height: 60px;
  margin-bottom: 30px;

  svg {
    color: ${colors.text};
    width: 4em;
  }
`;

export const Type = styled.h3`
  color: ${colors.text};
  opacity: 0.5;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 25px;
  display: block;
  text-align: center;
`;

export const Price = styled.div`
  font-size: 50px;
  line-height: 60px;
  color: ${colors.text};
  font-weight: 700;
  margin-bottom: 25px;

  span {
    font-size: 33px;
    line-height: 33px;
    position: relative;
    top: -12px;
  }
`;
