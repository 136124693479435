/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { FormattedIcon } from '@components/icons';
import { useForm } from 'react-hook-form';

// styles
import { Container, NormalText } from '@styles';
import {
  StyledMap,
  IconBox,
  Wrapper,
  FormWrapper,
  Row,
  FormGroup,
  StyledLabel,
  Required,
  StyledInput,
  StyledTextArea,
  ErrorMessage,
  SuccessMessage,
} from './styles';
import { SecondaryButton } from '@components/button';
import { LeftCol, RightCol } from '../faq/styles';
import { Row as CustomRow, Col } from '../birthdays/styles';

const Contact = () => {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    errors,
    reset,
    setError,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      fetch('https://l2u7jrwt6l.execute-api.us-east-2.amazonaws.com/prod', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'x-api-key': process.env.GATSBY_AWS_API,
        },
      });
      setSubmitted(true);
      reset();
    } catch (err) {
      setError('submit', err.message);
    }
  };

  return (
    <>
      <StyledMap
        title="Promotional"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.3735920747145!2d-71.19626968421994!3d42.33455767918873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3827f127087db%3A0x7717f7df7e2eb9ca!2sDynamo%20Fencing%20Center!5e0!3m2!1sen!2sus!4v1592691281326!5m2!1sen!2sus"
        width="100%"
        height={500}
        frameBorder={0}
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
      />
      <Container normal>
        <Wrapper>
          <CustomRow>
            <Col>
              <IconBox>
                <FormattedIcon name="phone" />
                <NormalText>(617) 356-7887</NormalText>
              </IconBox>
            </Col>
            <Col>
              <IconBox>
                <FormattedIcon name="pin" />
                <NormalText>
                  11 Homer St.
                  <br />
                  Newton Centre, MA 02459
                </NormalText>
              </IconBox>
            </Col>
            <Col>
              <IconBox>
                <FormattedIcon name="mail" />
                <NormalText>dynamo.fencing.center@gmail.com</NormalText>
              </IconBox>
            </Col>
          </CustomRow>
        </Wrapper>
        <FormWrapper>
          {submitted ? (
            <SuccessMessage>
              <h3>
                Thanks for visiting Dynamo. Someone will be in contact with you
                as soon as possible.
              </h3>
            </SuccessMessage>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <LeftCol>
                  <FormGroup>
                    <StyledLabel>
                      Name <Required>*</Required>
                    </StyledLabel>
                    <StyledInput
                      ref={register({
                        required: 'This input field is required',
                        pattern: {
                          value: /([A-Z]|[a-z])/,
                          message: 'Input contains invalid characters',
                        },
                      })}
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                    />
                    {errors.name && (
                      <ErrorMessage>{errors.name.message}</ErrorMessage>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Email <Required>*</Required>
                    </StyledLabel>
                    <StyledInput
                      ref={register({
                        required: 'This input field is required',
                        pattern: {
                          value: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
                          message: 'Input contains invalid characters',
                        },
                      })}
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                    />
                    {errors.email && (
                      <ErrorMessage>{errors.email.message}</ErrorMessage>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Subject <Required>*</Required>
                    </StyledLabel>
                    <StyledInput
                      ref={register({
                        required: 'This input field is required',
                        pattern: {
                          value: /^(?!.*[ ]{3})(?!.*[&#39;]{2})(?!.*[-]{2})(?![<.*>])(?:[a-zA-Z0-9()/\\:\?,"'!$% \p{L}&#39;-]{3,64}$)$/gm,
                          message: 'Input contains invalid characters',
                        },
                      })}
                      type="text"
                      id="subjet"
                      name="subject"
                      placeholder="Subject"
                    />
                    {errors.subject && (
                      <ErrorMessage>{errors.subject.message}</ErrorMessage>
                    )}
                  </FormGroup>
                </LeftCol>
                <RightCol>
                  <FormGroup>
                    <StyledLabel>
                      Message <Required>*</Required>
                    </StyledLabel>
                    <StyledTextArea
                      ref={register({
                        required: 'This input field is required',
                        pattern: {
                          value: /^(?!.*[ ]{3})(?!.*[&#39;]{2})(?!.*[-]{2})(?![<.*>])(?:[a-zA-Z0-9()/\\:\?,"'!$% \p{L}&#39;-]{3,64}$)$/gm,
                          message: 'Input contains invalid characters',
                        },
                      })}
                      rows={12}
                      cols={45}
                      name="message"
                      id="message"
                      placeholder="Message"
                    />
                    {errors.message && (
                      <ErrorMessage>{errors.message.message}</ErrorMessage>
                    )}
                  </FormGroup>
                </RightCol>
              </Row>
              <Row>
                {errors.submit && (
                  <ErrorMessage>{errors.submit.message}</ErrorMessage>
                )}
                <FormGroup>
                  <SecondaryButton disabled={isSubmitting} type="submit">
                    Send
                  </SecondaryButton>
                </FormGroup>
              </Row>
            </form>
          )}
        </FormWrapper>
      </Container>
    </>
  );
};

export default Contact;
